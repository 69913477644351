import { FC, ReactNode, useState } from 'react';
import { Box, FormControl, InputLabel, LinearProgress as MuiLinearProgress, MenuItem, Select } from '@material-ui/core';
import { Form, FormProps, useForm } from 'react-final-form';
import {
    Button as RaButton,
    minValue,
    ReferenceInput,
    required,
    SelectInput,
    TextInput,
    useGetManyReference,
    useGetResourceLabel,
    useSafeSetState,
    useTranslate,
} from 'react-admin';
import CancelIcon from '@material-ui/icons/Cancel';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';

import LoadingButton from '@components/button/LoadingButton';

import useTranslateResourceField from '@js/hooks/useTranslateResourceField';
import useGetIdentity from '@js/hooks/useGetIdentity';
import { minutes } from '@js/validator/minutes';

import { PredefinedText } from '@js/interfaces/predefinedtext';
import { Iri } from '@js/interfaces/ApiRecord';
import { GetManyReferenceResult } from '@components/types';

export type ProcessingTimeFormData = {
    '@id'?: Iri;
    time: string;
    userGroup?: Iri;
    description?: string;
};

export type SubmitHandler = FormProps<ProcessingTimeFormData>['onSubmit'];

interface Props {
    initialValues?: Partial<FormProps<ProcessingTimeFormData>['initialValues']>;
    onSubmit: SubmitHandler;
    onReset: () => void;
}

const resource = 'processing_times';

const FormRenderer = ({
    onSubmit,
    initialValues,
    onReset,
    children,
    isEditing,
}: Props & { children: ReactNode; isEditing: boolean }) => {
    const translate = useTranslate();
    const [key, setKey] = useSafeSetState(0);

    const restart = () => setKey((prev) => prev + 1);

    return (
        <Form<ProcessingTimeFormData>
            key={key}
            onSubmit={async (...args) => {
                try {
                    await onSubmit(...args);
                } finally {
                    restart();
                }
            }}
            initialValues={initialValues}
            render={({ handleSubmit, submitting }) => (
                <>
                    {children}
                    <Box my={2} display="flex" gridGap="5px">
                        <LoadingButton
                            onClick={handleSubmit}
                            label={translate(isEditing ? 'ra.action.edit' : 'ra.action.add')}
                            loading={submitting}
                            icon={isEditing ? <SaveIcon /> : <AddIcon />}
                        />
                        {isEditing && (
                            <RaButton
                                size="small"
                                color="default"
                                label="ra.action.cancel"
                                disabled={submitting}
                                onClick={() => {
                                    onReset();
                                    restart();
                                }}
                            >
                                <CancelIcon />
                            </RaButton>
                        )}
                    </Box>
                </>
            )}
        />
    );
};

const PredefinedSelectField: FC<{ predefinedTexts: PredefinedText[] }> = ({ predefinedTexts }) => {
    const getResourceLabel = useGetResourceLabel();
    const [value, setValue] = useState<Iri>(() => {
        return predefinedTexts.find((text) => text.isDefault)?.id.toString() ?? '';
    });
    const form = useForm();

    const handleChange = (newValue: Iri | string) => {
        const selected = newValue && predefinedTexts.find((text) => text.id.toString() === newValue);
        if (!selected) return;

        form.batch(() => {
            form.change('description', selected.text);
            form.change('time', selected.time?.toString());
        });
        setValue(newValue);
    };

    return (
        <FormControl margin="dense" fullWidth>
            <InputLabel>{getResourceLabel('predefined_texts')}</InputLabel>
            <Select
                value={value}
                onChange={(event) => handleChange(event.target.value as Iri)}
                disabled={predefinedTexts.length === 0}
            >
                <MenuItem value="">{' '}</MenuItem>
                {predefinedTexts.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                        {item.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

const UserGroupSelectField = ({ userGroups }: { userGroups: Iri[] }) => {
    const getResourceLabel = useGetResourceLabel();
    const hasUserGroups = userGroups.length > 0;
    const label = getResourceLabel('user_groups', 1);

    return (
        <Box minWidth="240px">
            {hasUserGroups ? (
                <ReferenceInput
                    reference="user_groups"
                    source="userGroup"
                    label={label}
                    resource={resource}
                    filter={{ id: userGroups }}
                    validate={required()}
                    allowEmpty
                >
                    <SelectInput variant="standard" optionText="name" fullWidth />
                </ReferenceInput>
            ) : (
                <FormControl margin="dense" disabled fullWidth>
                    <InputLabel>{label}</InputLabel>
                    <Select value="">
                        <MenuItem value=""></MenuItem>
                    </Select>
                </FormControl>
            )}
        </Box>
    );
};

const ProcessingTimeForm: FC<Props> = ({ onSubmit, onReset, initialValues }) => {
    const translateField = useTranslateResourceField(resource);

    const { identity } = useGetIdentity();
    const hasUserGroups = !!identity?.userGroups.length;

    const {
        ids,
        loaded: loadedTexts,
        data,
    } = useGetManyReference(
        'predefined_texts',
        'userGroup.users',
        identity?.id ?? 0,
        { page: 1, perPage: 99 },
        { field: 'createdAt', order: 'DESC' },
        {},
        'userGroups',
        {
            enabled: Boolean(identity?.id && hasUserGroups),
        },
    ) as GetManyReferenceResult<PredefinedText>;

    if (!identity) return null;

    if (hasUserGroups && !loadedTexts) {
        return (
            <Box justifyContent="center" mb={4}>
                <MuiLinearProgress />
            </Box>
        );
    }

    const predefinedTexts = ids.map((id) => data[id]).filter(Boolean);
    let isEditing = true;

    if (!initialValues) {
        const defaultText = predefinedTexts.find((text) => text.isDefault);

        initialValues = {
            description: defaultText?.text,
            time: defaultText?.time?.toString(),
            userGroup: identity.userGroups.length === 1 ? identity.userGroups[0] : undefined,
        };
        isEditing = false;
    }

    return (
        <FormRenderer initialValues={initialValues} isEditing={isEditing} onSubmit={onSubmit} onReset={onReset}>
            <Box display={{ xs: 'block', md: 'flex' }} gridGap={10}>
                <UserGroupSelectField userGroups={identity.userGroups} />
                <PredefinedSelectField predefinedTexts={predefinedTexts} />
            </Box>
            <Box display={{ xs: 'block', md: 'flex' }} gridGap={10}>
                <Box minWidth="240px">
                    <TextInput
                        type="number"
                        source="time"
                        label={translateField('timePlaceholder')}
                        resource={resource}
                        fullWidth
                        autoComplete="off"
                        validate={[minutes(), minValue(1), required()]}
                        variant="standard"
                    />
                </Box>
                <TextInput source="description" resource={resource} fullWidth variant="standard" multiline />
            </Box>
        </FormRenderer>
    );
};

export default ProcessingTimeForm;
